import * as React from 'react';
import './_confirm.scss';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ImportErrorCodes } from 'features/dispatch/nomination/nomination-constants';
library.add(faInfoCircle);

export interface ConfirmProps {
  onClose: () => void;
  uploadFlag;
}

interface ErrorState {
  errorDialog: boolean;
}

export class TruckRatesImportPopup extends React.Component<ConfirmProps,ErrorState> {
  constructor(props) {
    super(props);
    this.state = {
      errorDialog : true
    }
  }

  public render(): React.ReactNode {
    const { onClose, uploadFlag} = this.props;

    return (
      <div>
      {uploadFlag === false &&
        <div className="confirm __uploadingWidth">
         <div className='checker'>
          <Row className='mb-2'>
          <Col md={10}>File import is in progress</Col>
          <div className="spinner-border spinner-border-sm" style={{width: '1.4rem', height: '1.4rem'}} role="status"></div>
          </Row>
          
         </div>
         {/* <div style={{paddingBottom: 10}}>
          <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
              Close
          </CustomButton></div> */}
        </div>
        }

        {uploadFlag === true &&
        <div className="confirm __uploadingWidth">
        <div className='checker'>
         <Row className='mb-2'>
         <Col md={10}>File import is Completed</Col>
           
            {/* <Col md={1}><span style={{fontSize: '20px'}}>&#10060;</span></Col> */}
           
           {/* {this.formatErrors.length === 0 && uploadFlag === true && */}
           <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col>
           {/* } */}
         </Row>
         {/* <Row className='mb-2'>
         <Col md={10}>Location and O/D pairing is valid</Col>
           {(locationValidation() && this.locationInvalid === true) &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#10060;</span></Col>
           }
           {this.locationInvalid === false && uploadFlag === true &&
           <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col>
           }
         </Row>
         <Row className='mb-2'>
         <Col md={10}>Loads generated</Col>
           {(isLoadsGenerated() && this.loadsErrors.length > 0) &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#10060;</span></Col>}
            {this.formatErrors.length === 0 && this.locationErrors.length === 0 && this.loadsErrors.length === 0 && uploadFlag === true &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col> }
         </Row>
         <Row className='mb-2'>
         <Col md={10}>Import complete</Col>
           {(this.formatErrors.length === 0 && this.locationErrors.length === 0 && this.loadsErrors.length === 0) &&
            <Col md={1}><span style={{fontSize: '20px'}}>&#9989;</span></Col> }
         </Row> */}
        </div>
        <div style={{paddingBottom: 10}}>
         <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
             Ok
         </CustomButton></div>
       </div>}
{/*        
       </div> </div> } </div> */}
       </div>
    )
  }
}
