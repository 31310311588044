import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import { ReactLoader } from './shared/components/loader/loader';
import HeaderRouterComponent from 'shared/components/header/components';
import ProtectedRoute from './shared/components/protected-route';
import { Switch, Route } from 'react-router-dom';
import { AppConstant } from './app_constant';
import { DataManagementComponent } from 'features/data_management/components/data-management-component';
import { LoginContainer } from 'features/account/components/login-container';
import ErrorPage from 'shared/components/error-boundary/error-page';
import { accountStore } from 'features/account/stores';
import { observer } from 'mobx-react';
import { MaintenanceDataComponent } from 'features/maintenance-portal/components/maintenance-data-component';
import { InspectionUpComponent } from 'features/maintenance-portal/components/inspection-component';
import { EmailEntryComponent } from 'features/account/components/email-entry-component';
import { SignUpContainerComponent } from 'features/account/components/sign-up-container';
import { UserRequestComponent } from 'features/account/components/user-request-component';
import { DispatcherApprovalComponent } from 'features/account/components/dispatcher-approval-compnent';
import { UserApprovalMainComponent } from 'features/userApprovalFlow/components/user-approval-component';
import { DispatchManagementComponent } from 'features/dispatch/dispatch-management/components/dispatch-management';
import { ReportManagementComponent } from 'features/reports/components/report-management-component';
import { LicenseManager } from 'ag-grid-enterprise';
import { OTESHistoryComponent } from 'features/maintenance-portal/components/otes-component';
import { OperationsDataComponent } from 'features/operations-portal/components/operations-data-component';
import { HazardAssessmentUpComponent } from 'features/operations-portal/components/hazardAssessment-component';
import { InfoComponent } from 'features/account/components/info-component';
import { TruckRatesComponent } from 'features/operations-portal/components/truckRates-component';

LicenseManager.setLicenseKey(
    'CompanyName=PLAINS MIDSTREAM CANADA ULC,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=1,AssetReference=AG-015081,ExpiryDate=27_April_2022_[v2]_MTY1MTAxNDAwMDAwMA==ca6bf28c53b244f417807f5d9fa9e08a'
);
const App = (): JSX.Element => {
    return (
        <Fragment>
            <ReactLoader visible={false} />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                className="toast-container"
            />
            {accountStore.isLoggedIn ? (
                <HeaderRouterComponent
                    onLogout={accountStore.logOut}
                    userName={accountStore.displayName}
                    roles={accountStore.userRoles}
                />
            ) : null}
            <Switch>
                <Route path="/login" component={LoginContainer} />
                <Route path="/emailentry" component={EmailEntryComponent} />
                <Route path="/signup" component={SignUpContainerComponent} />
                <Route path="/userrequest" component={UserRequestComponent} />
                <Route path="/defect" component={InspectionUpComponent} />
                <Route path="/info" component={InfoComponent} />
                <Route path="/otes" component={OTESHistoryComponent} />
                <Route path="/hazardAssessment" component={HazardAssessmentUpComponent} />
                <Route path="/approveUserDispatcher" component={DispatcherApprovalComponent} />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/communication"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/nomination"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/nominationWorkbench"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/apportionment"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/dispatchPlan"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/capacityPlan"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/dispatchBoard"
                    component={DispatchManagementComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/dispatch/dispatchScreen"
                    component={DispatchManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/maintenance"
                    component={MaintenanceDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/maintenance/inspectionTicketHistory"
                    component={MaintenanceDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/maintenance/trailerBookingMaint"
                    component={MaintenanceDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/operations"
                    component={OperationsDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/operations/ticketApproval"
                    component={OperationsDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/operations/hazardAssessmentHistory"
                    component={OperationsDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/operations/pastDueCertificationReport"
                    component={OperationsDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/operations/truckRates"
                    component={OperationsDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/userApproval"
                    component={UserApprovalMainComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/userApproval/manageUsers"
                    component={UserApprovalMainComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/driverMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/trailerMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/tractorMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/companyMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/shopMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/tabletMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/printerMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/locationMgt"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/locationMap"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/keyFobs"
                    component={DataManagementComponent}
                />  
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    path="/regionMapping"
                    component={DataManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/reports"
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/reports/dailyReport"
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/reports/leaseOperator"
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/reports/hourlyReport"
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/reports/customerReport"
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/reports/scheduledReports"
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/reports/rejectedLoadsReport"
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path='/reports/upcomingCertificationReport'
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path='/reports/searchTickets'
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path='/reports/masterTicketReport'
                    component={ReportManagementComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path='/reports/truckRatesReport'
                    component={ReportManagementComponent}
                />
                <Route component={ErrorPage} />
            </Switch>
        </Fragment>
    );
};

export default observer(App);
