import { ColDef } from "ag-grid-community";
import { truckRatesFieldName, truckRatesHeader } from "../constants/truckRates-enum";
import { CheckBoxCellRenderer } from "../components/truckRates-checkbox-renderer";
import { TextAreaCellRenderer } from "shared/components/cell-renderers/text-area-cell-renderer";


export const truckRatesColDef: ColDef[] = [
    {
        headerName: 'Select',
        field: 'IsSelected',
        width: 75,
        cellRendererFramework: CheckBoxCellRenderer
    },
    {
        headerName: truckRatesHeader.QUOTEID,
        field: truckRatesFieldName.QUOTEID,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.QUOTEID] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.QUOTEID];
            }
        },
        cellRenderer: params => {
            return `<a href="#" style="color: blue; text-decoration: underline;">${params.data[truckRatesFieldName.QUOTEID] === null ? '' : params.data[truckRatesFieldName.QUOTEID]}</a>`;
        }
    },
    {
        headerName: truckRatesHeader.QUOTESTATUS,
        field: truckRatesFieldName.QUOTESTATUS,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.QUOTESTATUS] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.QUOTESTATUS];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNOR,
        field: truckRatesFieldName.CONSIGNOR,
        width: 110,
        cellClass: (params) => (params.data.IsOriginError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNOR] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNOR];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNORLSD,
        field: truckRatesFieldName.CONSIGNORLSD,
        width: 110,
        cellClass: (params) => (params.data.IsOriginError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNORLSD] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNORLSD];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNEE,
        field: truckRatesFieldName.CONSIGNEE,
        width: 110,
        cellClass: (params) => (params.data.IsDestinationError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNEE] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNEE];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNEELSD,
        field: truckRatesFieldName.CONSIGNEELSD,
        width: 110,
        cellClass: (params) => (params.data.IsDestinationError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNEELSD] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNEELSD];
            }
        }
    },
    {
        headerName: truckRatesHeader.TRANSPORTTYPE,
        field: truckRatesFieldName.TRANSPORTTYPE,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.TRANSPORTTYPE] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.TRANSPORTTYPE];
            }
        }
    },
    {
        headerName: truckRatesHeader.PRODUCT,
        field: truckRatesFieldName.PRODUCT,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.PRODUCT];
            }
        }
    },
    
    {
        headerName: truckRatesHeader.LEASEOPERATORRATE,
        field: truckRatesFieldName.LEASEOPERATORRATE,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.LEASEOPERATORRATE] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.LEASEOPERATORRATE];
            }
        }
    },
    {
        headerName: truckRatesHeader.PRODUCERRATE,
        field: truckRatesFieldName.PRODUCERRATE,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.PRODUCERRATE] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.PRODUCERRATE];
            }
        }
    },
    {
        headerName: truckRatesHeader.MARGIN,
        field: truckRatesFieldName.MARGIN,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.MARGIN] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.MARGIN];
            }
        }
    },
    
    {
        headerName: truckRatesHeader.COMMENTS,
        field: truckRatesFieldName.COMMENTS,
        width: 110,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => true
        },
        valueGetter: params => {
            if (params.data[truckRatesFieldName.COMMENTS] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.COMMENTS];
            }
        }
    },
    {
        headerName: truckRatesHeader.LINKTOPDF,
        field: truckRatesFieldName.LINKTOPDF,
        width: 110,
        valueGetter: () => 'LinkToPDF',
        cellRenderer: params => {
            return `<a href="#" style="color: blue; text-decoration: underline;">Link</a>`;
        }
    }
]

// Seperate coldef for the trucking manager role without ProcducerRate n Margin% column 
export const truckRatesMngrColDef: ColDef[] = [
    {
        headerName: 'Select',
        field: 'IsSelected',
        width: 75,
        cellRendererFramework: CheckBoxCellRenderer
    },
    {
        headerName: truckRatesHeader.QUOTEID,
        field: truckRatesFieldName.QUOTEID,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.QUOTEID] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.QUOTEID];
            }
        },
        cellRenderer: params => {
            return `<a href="#" style="color: blue; text-decoration: underline;">${params.data[truckRatesFieldName.QUOTEID] === null ? '' : params.data[truckRatesFieldName.QUOTEID]}</a>`;
        }
    },
    {
        headerName: truckRatesHeader.QUOTESTATUS,
        field: truckRatesFieldName.QUOTESTATUS,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.QUOTESTATUS] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.QUOTESTATUS];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNOR,
        field: truckRatesFieldName.CONSIGNOR,
        width: 110,
        cellClass: (params) => (params.data.IsOriginError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNOR] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNOR];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNORLSD,
        field: truckRatesFieldName.CONSIGNORLSD,
        width: 110,
        cellClass: (params) => (params.data.IsOriginError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNORLSD] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNORLSD];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNEE,
        field: truckRatesFieldName.CONSIGNEE,
        width: 110,
        cellClass: (params) => (params.data.IsDestinationError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNEE] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNEE];
            }
        }
    },
    {
        headerName: truckRatesHeader.CONSIGNEELSD,
        field: truckRatesFieldName.CONSIGNEELSD,
        width: 110,
        cellClass: (params) => (params.data.IsDestinationError && params.data.QuoteStatus !== 'Econ' ? 'trailer-ques-bgColor-red' : ''),
        valueGetter: params => {
            if (params.data[truckRatesFieldName.CONSIGNEELSD] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.CONSIGNEELSD];
            }
        }
    },
    {
        headerName: truckRatesHeader.TRANSPORTTYPE,
        field: truckRatesFieldName.TRANSPORTTYPE,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.TRANSPORTTYPE] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.TRANSPORTTYPE];
            }
        }
    },
    {
        headerName: truckRatesHeader.PRODUCT,
        field: truckRatesFieldName.PRODUCT,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.PRODUCT];
            }
        }
    },
    
    {
        headerName: truckRatesHeader.LEASEOPERATORRATE,
        field: truckRatesFieldName.LEASEOPERATORRATE,
        width: 110,
        valueGetter: params => {
            if (params.data[truckRatesFieldName.LEASEOPERATORRATE] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.LEASEOPERATORRATE];
            }
        }
    },
    {
        headerName: truckRatesHeader.COMMENTS,
        field: truckRatesFieldName.COMMENTS,
        width: 110,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => true
        },
        valueGetter: params => {
            if (params.data[truckRatesFieldName.COMMENTS] === null) {
                return '';
            } else {
                return params.data[truckRatesFieldName.COMMENTS];
            }
        }
    },
    {
        headerName: truckRatesHeader.LINKTOPDF,
        field: truckRatesFieldName.LINKTOPDF,
        width: 110,
        valueGetter: () => 'LinkToPDF',
        cellRenderer: params => {
            return `<a href="#" style="color: blue; text-decoration: underline;">Link</a>`;
        }
    }
]