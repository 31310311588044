import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { dataStore } from 'features/common/stores';
import { truckRatesDataStore } from '../stores';

interface AlphaNumericCellRendererState {
    value: boolean;
}
export class CheckBoxCellRenderer extends PureComponent<ICellRendererParams, AlphaNumericCellRendererState, {}> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : false
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    handleCheckboxChange() {
        this.setState({ value: !this.state.value });
        truckRatesDataStore.handleCheckboxClick(this.props.data, this.state.value, this.props.column.getColId());
    }

    render() {
        return <input type="checkbox" disabled={!dataStore.checkOperationAccess('Save') || ((this.props.data.IsOriginError || this.props.data.IsDestinationError) && this.props.data.QuoteStatus !== 'Econ')} checked={this.state.value} onChange={this.handleCheckboxChange} />;
    }
}
