import './index.scss';
// TODO Move all css ref to different file
import 'bootstrap/dist/css/bootstrap.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-day-picker/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.scss';

import { accountStore } from './features/account/stores';
import { dataStore } from 'features/common/stores';
import { trailerMaintenanceStore } from 'features/maintenance-portal/stores';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import history from './shared/services/history';
import { localStorageService } from 'shared/services/local-storage-service';
import { AuthToken } from 'shared/types/shared';
import { AppConstant } from 'app_constant';
import { inspectionDataStore } from 'features/maintenance-portal/stores/index';
import { mappingDataStore } from 'features/mapping/store/index';
import { userApprovalStore } from 'features/userApprovalFlow/stores/index';
import { dispatchBoardDataStore } from 'features/dispatch/dispatch-board/store'
import { capacityPlanDataStore } from 'features/dispatch/capacityPlan/store/index';
import { hazardAssessmentDataStore } from 'features/operations-portal/stores';
import { ticketStore } from 'features/operations-portal/stores';
import { truckRatesDataStore } from 'features/operations-portal/stores';

const rootStore = {
    dataStore,
    accountStore,
    trailerMaintenanceStore,
    inspectionDataStore,
    mappingDataStore,
    ticketStore,
    userApprovalStore,
    dispatchBoardDataStore,
    capacityPlanDataStore,
    hazardAssessmentDataStore,
    truckRatesDataStore,
};

const authTokenOnPageLoad = localStorageService.get<AuthToken>(AppConstant.AUTHTOKEN);
if (authTokenOnPageLoad) {
    accountStore.setAuthInfo(authTokenOnPageLoad, true);
}

ReactDOM.render(
    <Provider {...rootStore}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
