export const dataTypeOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'DailyReport', label: 'Daily Report' },
    { value: 'MasterTicketReport', label: 'Master Ticket Report' },
    { value: 'HourlyReport', label: 'Hourly Report' },
    { value: 'LeaseOperatorReport', label: 'Carrier Company Report' },
    { value: 'CustomerReport', label: 'Customer Report' },
    { value: 'RejectedLoads', label: 'Rejected Loads' },
    { value: 'ScheduledReports', label: 'Scheduled Reports' },
    { value: 'UpcomingCertificationReport', label: 'Upcoming Certifications Report'},
    { value: 'SearchTickets', label: 'Search Tickets' },
    { value: 'TruckRatesReport', label: 'Truck Rates Report' }
];

export const statusType = [
    { value: 'Driver Accepted', label: 'Driver Accepted' },
    { value: 'Enroute', label: 'Enroute' },
    { value: 'Loaded', label: 'Loaded' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'System Completed', label: 'System Completed' }
];

export const MasterTicketstatusType = [
    { value: 'New', label: 'New' },
    { value: 'In Review', label: 'In Review'},
    { value: 'Approved', label: 'Approved' },
    { value: 'Not Approved', label: 'Not Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Drafted', label: 'Drafted' },
    { value: 'Driver Dispatched', label: 'Driver Dispatched' },
    { value: 'Carrier Assigned', label: 'Carrier Assigned' },
    { value: 'Driver Accepted', label: 'Driver Accepted' },
    { value: 'Carrier Accepted', label: 'Carrier Accepted' },
    { value: 'Enroute', label: 'Enroute' },
    { value: 'Loaded', label: 'Loaded' },
    { value: 'Recalled', label: 'Recalled' },
    { value: 'Recalled By Carrier', label: 'Recalled By Carrier' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'System Completed', label: 'System Completed' },
    { value: 'Dispatch_Pending', label: 'Dispatch_Pending' },
    { value: 'Recalled_Pending', label: 'Recalled_Pending' },
    { value: 'Cancelled_Pending', label: 'Cancelled_Pending' },
    { value: 'Rejected By Driver', label: 'Rejected By Driver' },
    { value: 'Rejected By Carrier', label: 'Rejected By Carrier' },
    { value: 'Cancelled By Driver', label: 'Cancelled By Driver' },
    { value: 'Cancelled By Carrier', label: 'Cancelled By Carrier' },
];

export const ticketType = [
    { value: 'All', label: 'All' },
    { value: 'Volume', label: 'Volume'},
    { value: 'Hourly', label: 'Hourly'}
]

export const searchByType = [
    { value: 'TicketNumber', label: 'Ticket Number' },
    { value: 'DriverDetails', label: 'Driver Details' }
];

export const dateTypeArray = [
    {value: 'LoadDate', label: 'Load Date'},
    {value: 'CreatedDate', label: 'Created Date'},
    {value: 'CompletedDate', label: 'Completed Date'}
];

export const ticketStatus = [
    { value: 'New', label: 'New' },
    { value: 'Driver Dispatched', label: 'Driver Dispatched/Dispatch Pending' },
    { value: 'Carrier Assigned', label: 'Carrier Assigned' },
    { value: 'Driver Accepted', label: 'Driver Accepted' },
    { value: 'Carrier Accepted', label: 'Carrier Accepted' },
    { value: 'Enroute', label: 'Enroute' },
    { value: 'Loaded', label: 'Loaded' },
    { value: 'In Review', label: 'In Review'},
    { value: 'Approved', label: 'Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Recalled By Carrier', label: 'Recalled By Carrier' },
    { value: 'Completed', label: 'Completed' },
    { value: 'System Completed', label: 'System Completed' }
];

export const reportticketStatus = [
    { value: 'New', label: 'New' },
    { value: 'Approved', label: 'Approved' },
    { value: 'In Review', label: 'In Review'},
    { value: 'Not Approved', label: 'Not Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Completed', label: 'Completed' },
    { value: 'System Completed', label: 'System Completed' }
];

export const customerReportStatus = [
    { value: 'All', label: 'All' },
    { value: 'New', label: 'New' },
    { value: 'In Review', label: 'In Review'},
    { value: 'Approved', label: 'Approved' },
    { value: 'Not Approved', label: 'Not Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Completed', label: 'Completed' }
];

export const CCUserRoles = [
    'PTSOwnerOperator',
    'PTSCarrierCompanyScheduler',
    'PTSCarrierOwner',
    'PTSCarrierCompanyAdmin',
    'ptsthirdpartydispatcher'
];

export const commaFormat = (number) => {
    return number != null && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const isFloat = (n) => {
    return (n - Math.floor(n)) !== 0
}

export const RejectedLoadsStatus = [
    { value: 'Rejected By Driver', label: 'Rejected By Driver' },
    { value: 'Cancelled By Driver', label: 'Cancelled By Driver' },
    { value: 'Rejected By Carrier', label: 'Rejected By Carrier' },
    { value: 'Cancelled By Carrier', label: 'Cancelled By Carrier' }
]


export const Reason = [
    {
        ID: 1,
        AXID: 5637144576,
        Name: 'Additonal Equipment Required',
        Version: 3
    },
    {
        ID: 2,
        AXID: 5637144577,
        Name: 'Butane Transfer',
        Version: 1
    },
    {
        ID: 3,
        AXID: 5637144578,
        Name: 'Chain Up',
        Version: 1
    },
    {
        ID: 4,
        AXID: 5637144579,
        Name: 'Chain Up & Slow Loading',
        Version: 1
    },
    {
        ID: 5,
        AXID: 5637144580,
        Name: 'Condensate Transfer',
        Version: 1
    },
    {
        ID: 6,
        AXID: 5637144581,
        Name: 'Crude Transfer',
        Version: 1
    },
    {
        ID: 7,
        AXID: 5637144582,
        Name: 'Emulsion transfer',
        Version: 1
    },
    {
        ID: 8,
        AXID: 5637144583,
        Name: 'Extra Pumping Time',
        Version: 1
    },
    {
        ID: 9,
        AXID: 5637144584,
        Name: 'High Tanked loading',
        Version: 1
    },
    {
        ID: 10,
        AXID: 5637144585,
        Name: 'High Tanked Unloading',
        Version: 1
    },
    {
        ID: 11,
        AXID: 5637144586,
        Name: 'Hourly Haul Only',
        Version: 1
    },
    {
        ID: 12,
        AXID: 5637144587,
        Name: 'Load Re-directed',
        Version: 1
    },
    {
        ID: 13,
        AXID: 5637144588,
        Name: 'Low Tanked loading',
        Version: 1
    },
    {
        ID: 14,
        AXID: 5637144589,
        Name: 'NGL Transfer',
        Version: 1
    },
    {
        ID: 15,
        AXID: 5637144590,
        Name: 'No Load',
        Version: 1
    },
    {
        ID: 16,
        AXID: 5637144591,
        Name: 'Rejected Load',
        Version: 1
    },
    {
        ID: 17,
        AXID: 5637144592,
        Name: 'Rejected Load - Wet',
        Version: 1
    },
    {
        ID: 18,
        AXID: 5637144593,
        Name: 'Road Ban',
        Version: 1
    },
    {
        ID: 19,
        AXID: 5637144594,
        Name: 'Short Load Adjustment',
        Version: 1
    },
    {
        ID: 20,
        AXID: 5637144595,
        Name: 'Slow loading',
        Version: 1
    },
    {
        ID: 21,
        AXID: 5637144596,
        Name: 'Slow Loading & Unloading',
        Version: 1
    },
    {
        ID: 22,
        AXID: 5637144597,
        Name: 'Slow Loading, Gassy Oil',
        Version: 1
    },
    {
        ID: 23,
        AXID: 5637144598,
        Name: 'Slow unloading',
        Version: 1
    },
    {
        ID: 24,
        AXID: 5637144599,
        Name: 'Slow Unloading, Gassy Oil',
        Version: 1
    },
    {
        ID: 25,
        AXID: 5637144600,
        Name: 'Split load - Extra Time',
        Version: 1
    },
    {
        ID: 26,
        AXID: 5637144601,
        Name: 'Travel Time',
        Version: 1
    },
    {
        ID: 27,
        AXID: 5637144602,
        Name: 'Waiting to Load',
        Version: 1
    },
    {
        ID: 28,
        AXID: 5637144603,
        Name: 'Waiting to unload',
        Version: 1
    },
    {
        ID: 29,
        AXID: 5637144604,
        Name: 'Water Transfer',
        Version: 1
    },
    {
        ID: 30,
        AXID: 5637146105,
        Name: 'Chain Up & Slow Unloading',
        Version: 1
    },
    {
        ID: 31,
        AXID: 5637146106,
        Name: 'Waiting to Load & Unload',
        Version: 1
    }
];

export const rowDetails = [
    
    {
      "QuoteID": "Q-1112227",
      "QuoteStatus": "In Review",
      "Consignor": "Empress",
      "ConsignorLSD": "10-11-020-01W4 (16-11)",
      "Consignee": "PMC Gull Lake",
      "ConsigneeLSD": "14-11-013-19W3",
      "TransportType": 208,
      "LoadingDistrict": 104,
      "Product": "Butane",
      "Density": 23,
      "LoadSize": 57,
      "GravelSpeed": 25,
      "PavedSpeed": 50,
      "GravelMiles": 3,
      "PavedMiles": 152,
      "TotalMiles": 310,
      "DriveTime": 6,
      "PumpTime": 4,
      "ExtraTime": 1,
      "AdjustedTime": 2,
      "LeaseOperatorRate": 23,
      "ProducerRate": 43,
      "Margin": 44,
      "Comments": "qweqwertty",
      "EffectiveDate": "2024-04-02T00:00:00Z",
      "ExpiryDate": "2025-04-12T00:00:00Z",
      "CreatedBy": 1223,
      "CalculationDate": null,
      "Version": 1,
      "CreatedDate": "2025-01-01T10:40:56.28"
    }
,
  
    {
      "QuoteID": "Q-1112227",
      "QuoteStatus": "In Review",
      "Consignor": "Empress",
      "ConsignorLSD": "10-11-020-01W4 (16-11)",
      "Consignee": "PMC Gull Lake",
      "ConsigneeLSD": "14-11-013-19W3",
      "TransportType": 208,
      "LoadingDistrict": 104,
      "Product": "Butane",
      "Density": 23,
      "LoadSize": 57,
      "GravelSpeed": 25,
      "PavedSpeed": 50,
      "GravelMiles": 3,
      "PavedMiles": 152,
      "TotalMiles": 310,
      "DriveTime": 6,
      "PumpTime": 4,
      "ExtraTime": 1,
      "AdjustedTime": 2,
      "LeaseOperatorRate": 23,
      "ProducerRate": 43,
      "Margin": 44,
      "Comments": "qweqwertty",
      "EffectiveDate": "2024-04-02T00:00:00Z",
      "ExpiryDate": "2025-04-12T00:00:00Z",
      "CreatedBy": 1223,
      "CalculationDate": null,
      "Version": null,
      "CreatedDate": "2025-01-01T10:40:48.4"
    },
    {
      "QuoteID": "Q-1112225",
      "QuoteStatus": "In Review",
      "Consignor": "Empress",
      "ConsignorLSD": "10-11-020-01W4 (16-11)",
      "Consignee": "PMC Gull Lake",
      "ConsigneeLSD": "14-11-013-19W3",
      "TransportType": 208,
      "LoadingDistrict": 104,
      "Product": "Butane",
      "Density": 23,
      "LoadSize": 57,
      "GravelSpeed": 25,
      "PavedSpeed": 50,
      "GravelMiles": 3,
      "PavedMiles": 152,
      "TotalMiles": 310,
      "DriveTime": 6,
      "PumpTime": 4,
      "ExtraTime": 1,
      "AdjustedTime": 2,
      "LeaseOperatorRate": 23,
      "ProducerRate": 43,
      "Margin": 44,
      "Comments": "qweqwertty",
      "EffectiveDate": "2024-04-02T00:00:00Z",
      "ExpiryDate": "2025-04-12T00:00:00Z",
      "CreatedBy": 1223,
      "CalculationDate": null,
      "Version": 1,
      "CreatedDate": "2025-01-01T09:02:28.493"
    },
    {
      "QuoteID": "Q-1112225",
      "QuoteStatus": "In Review",
      "Consignor": "Empress",
      "ConsignorLSD": "10-11-020-01W4 (16-11)",
      "Consignee": "PMC Gull Lake",
      "ConsigneeLSD": "14-11-013-19W3",
      "TransportType": 208,
      "LoadingDistrict": 104,
      "Product": "Butane",
      "Density": 23,
      "LoadSize": 57,
      "GravelSpeed": 25,
      "PavedSpeed": 50,
      "GravelMiles": 3,
      "PavedMiles": 152,
      "TotalMiles": 310,
      "DriveTime": 6,
      "PumpTime": 4,
      "ExtraTime": 1,
      "AdjustedTime": 2,
      "LeaseOperatorRate": 23,
      "ProducerRate": 43,
      "Margin": 44,
      "Comments": "qweqwertty",
      "EffectiveDate": "2024-04-02T00:00:00Z",
      "ExpiryDate": "2025-04-12T00:00:00Z",
      "CreatedBy": 1223,
      "CalculationDate": null,
      "Version": null,
      "CreatedDate": "2025-01-01T09:02:04.56"
    }
]