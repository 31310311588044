import React, { PureComponent } from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { observer } from 'mobx-react';
import logo from '../../../assets/pmc-logo.png';

interface PDFComponentProps {
    quoteData: any[];
    values: any;
}

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        padding: 15,
        paddingBottom: 35,
        marginBottom: 5,
    },
    borderView: { borderWidth: 1 ,paddingHorizontal: 15, paddingBottom: 15},
    blankSpaceTop: {  marginBottom: 15},
    blankSpaceBottom: {
        position: 'absolute',
        bottom: 10,
      },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    logoSection: {
       
    },
    logo: {
        width: 150,
        height: 50,
      
    },
    titleSection: {
        flexDirection: 'column',
        padding: 2,
    },
    title_1: {
        fontSize: 9,
        padding: 10,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 900,
    },
    title_2: {
        fontSize: 9,
        padding: 10,
        textTransform: 'uppercase',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 900,
        marginLeft: 15,
       
    },
    addressBlock: {
        fontSize: 9,
        padding: 2,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    customerInfo: {
        marginBottom: 10,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    label: {
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 900,
        flex: 1,
        textAlign: 'left',
    },
    value: {
        fontSize: 9,
        flex: 7,
    },
    tableContainer: {
        marginTop: 5,
        borderTopWidth: 1,
        borderTopColor: '#000',
        flexDirection: 'column',
        
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#38599c',
        color: '#fff',
        textAlign: 'center',
        fontSize: 9,
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        fontSize: 8,
    },
    tableCellHeader: {
        padding: 5,
        textAlign: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderLeftColor: '#000',
        borderLeftWidth: 1,
    },
    tableHeaderLast: {
        borderRightColor: '#000',
        borderRightWidth: 1,
    },
    tableCell: {
        paddingLeft: 3,
        paddingTop: 6,
        paddingBottom:2,
        paddingRight: 6,
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderLeftWidth: 1,
        borderLeftColor: '#000',
    },
    tableCellLast: {
        borderRightColor: '#000',
        borderRightWidth: 1,
    },
    notes: {
        marginTop: 15,
        fontSize: 8,
    },
    column1: { width: '13%' },
    column2: { width: '13%' },
    column3: { width: '13%' },
    column4: { width: '13%' },
    column5: { width: '8%' },
    column6: { width: '7%' },
    column7: { width: '8%' },
    column8: { width: '8%' },
    column9: { width: '9%' },
    column10: { width: '8%' },
});

@observer
export class MyDocument extends PureComponent<PDFComponentProps> {
    constructor(props: Readonly<PDFComponentProps>) {
        super(props);
    }
    parseNoteContent(data: string) {
        const cleanedContent = data.replace(/<\/?Text>/g, '').trim();
        const parts = cleanedContent.split('\n');
        return parts.map((part, index) => {
            if (part.trim()) {
                return <Text key={index} style={{ marginBottom: 5 }}>{part.trim()}</Text>;
            }
            return null;
        });
    }

    public render(): React.ReactNode {
        const { quoteData, values } = this.props;
        const noteContent = values.notes;
        const addressContent = values.address;
        return (
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View  style={styles.borderView}>  
                        <View fixed style={styles.blankSpaceTop}>    
                        </View>
                    
                        {/* Header Section */}
                        <View style={styles.header}>
                            <View style={styles.logoSection}>
                                <Image src={logo} style={styles.logo} />
                            </View>
                            <View style={styles.titleSection}>
                                <Text style={styles.title_1}>Dependable Transportation Service</Text>
                                <Text style={styles.title_2}>Freight Rate Quotation</Text>
                            </View>
                            <View style={styles.addressBlock}>
                                {this.parseNoteContent(addressContent)}
                            </View>
                        </View>

                        {/* Customer Info */}
                        <View style={styles.customerInfo}>
                            <View style={styles.row}>
                                
                                    <Text style={styles.label}>Customer</Text>
                                    <Text style={styles.value}>{values.customerName}</Text>
                                
                            </View>
                            <View style={styles.row}>
                                
                                    <Text style={styles.label}>Effective Date</Text>
                                    <Text style={styles.value}>{values.effectiveDate}</Text>
                                
                            </View>
                        </View>

                        {/* Table Header */}
                        <View style={styles.tableContainer}>
                            <View style={styles.tableHeader} >
                                <Text style={[styles.tableCellHeader, styles.column1]}>Source</Text>
                                <Text style={[styles.tableCellHeader, styles.column2]}>Source LSD</Text>
                                <Text style={[styles.tableCellHeader, styles.column3]}>Destination</Text>
                                <Text style={[styles.tableCellHeader, styles.column4]}>Destination LSD</Text>
                                <Text style={[styles.tableCellHeader, styles.column5]}>Product</Text>
                                <Text style={[styles.tableCellHeader, styles.column6]}>Axles</Text>
                                <Text style={[styles.tableCellHeader, styles.column7]}>Round Trip Miles</Text>
                                <Text style={[styles.tableCellHeader, styles.column8]}>Rate Per m3</Text>
                                <Text style={[styles.tableCellHeader, styles.column9]}>Estimated Load Size(m3)</Text>
                                <Text style={[styles.tableCellHeader,styles.tableHeaderLast, styles.column10]}>Quote Type</Text>
                            </View>

                            {/* Table Rows */}
                            {quoteData.map((row, index) => (
                                <View style={styles.tableRow} key={index} wrap={false}>
                                    <Text style={[styles.tableCell, styles.column1]}>{row.Consignor || '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column2]}>{row.ConsignorLSD || '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column3]}>{row.Consignee || '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column4]}>{row.ConsigneeLSD || '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column5]}>{row.Product || '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column6]}>{row.TransportType ? row.TransportType.slice(-1) : '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column7]}>{row.TotalMiles || '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column8]}>{row.ProducerRate? '$  '+row.ProducerRate : '-'}</Text>
                                    <Text style={[styles.tableCell, styles.column9]}>{row.LoadSize || '-'}</Text>
                                    <Text style={[styles.tableCell, styles.tableCellLast, styles.column10]}>{row.QuoteStatus || '-'}</Text>
                                </View>
                            ))}
                        </View>

                        {/* Notes Section */}
                        <View style={styles.notes}>
                            {this.parseNoteContent(noteContent)}
                        </View>
                        <View style={styles.blankSpaceBottom} fixed />
                   </View>
                   
                </Page>
            </Document>
        );
    }
}
