import React, { Component } from 'react';
import { FilterChangedEvent, GridOptions } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import autoTable from 'jspdf-autotable';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import jsPDF from 'jspdf';
import { HourlyReportDataStore } from '../stores/hourlyreport-data-store';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import { TruckRatesReportDataStore } from '../stores/truckRatesreport-data-store';
import { truckRatesHeader, truckRatesFieldName } from 'features/operations-portal/constants/truckRates-enum';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { rowDetails } from '../reports_constants';
import { toJS } from 'mobx';
import { truckRatesMngrColDef, truckRatesreportColDef } from '../domains/truckRatesreport-col-def';
import Select from 'react-select';
import { truckRatesReportDataStore } from '../stores';
import { TruckRatesStatus } from 'features/operations-portal/operations_constants';
import { accountStore } from 'features/account/stores';
/* eslint-disable */
interface TruckRatesReportProps {
    truckRatesReportDataStore: TruckRatesReportDataStore;
}

@observer
export class TruckRatesReportComponent extends Component<TruckRatesReportProps> {
    startDate: any;
    endDate: any;
    from: any = moment()
        .subtract(6, 'days')
        .toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    constructor(props) {
        super(props);
        dataStore.setModuleName('TruckRatesReport');
    }

    componentDidMount() {
        this.props.truckRatesReportDataStore.init();
    }

    render(): React.ReactNode {
        const {
            truckRatesReportDataStore: { dailyBackupReport, leaseOperatorName,consigneeName }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                    <Row className="mt-3" style={{ paddingLeft: '20px' }}>
                        <div>
                            <div className="form-inline padding-bottom">
                                Consignor :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={truckRatesReportDataStore.selectedConsignor}
                                        placeholder='Select Consignor'
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        onChange={this.handleConsignorChange}
                                        options={truckRatesReportDataStore.leaseOperatorName}
                                        isMulti={true}
                                    /> </div>
                                Consignee :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={truckRatesReportDataStore.selectedConsignee}
                                        placeholder='Select Consignee'
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        onChange={this.handleConsigneeChange}
                                        options={truckRatesReportDataStore.consigneeName}
                                        isMulti={true}
                                    /> </div>
                                Status :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={truckRatesReportDataStore.selectedStatus}
                                        placeholder='Select Status'
                                        closeMenuOnSelect={true}
                                        closeMenuOnScroll={true}
                                        onChange={this.handleStatusChange}
                                        options={TruckRatesStatus}
                                        isMulti={true}
                                    /> </div>
                            </div>
                        </div>
                    </Row>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    <ClearButtonComponent
                                        handleRefresh={this.handleRefresh}
                                        isExportCSV={true}
                                    ></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('TruckRatesReport_filter');
        this.props.truckRatesReportDataStore.agGridService.setAllFilters(filters);
    }

    handleRefresh = () => {
        const { truckRatesReportDataStore } = this.props;
        truckRatesReportDataStore.getHourlyReports();
    }

    handleConsignorChange = (option) => {
        const { truckRatesReportDataStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            this.setState((truckRatesReportDataStore.selectedConsignor = option));
        } else {
            this.setState((truckRatesReportDataStore.selectedConsignor = []));
        }
        this.props.truckRatesReportDataStore.statusFilterrecords();
        if(truckRatesReportDataStore.selectedConsignee.length === 0) {
            truckRatesReportDataStore.consigneeName = [];
            truckRatesReportDataStore.consignee = Array.from(new Set(truckRatesReportDataStore.truckRatesReports.map((item: any) => item.Consignee)));
            truckRatesReportDataStore.consignee.forEach(name => {
                truckRatesReportDataStore.consigneeName.push({ value: name, label: name });
        });}
    }

    handleConsigneeChange = (option) => {
        const { truckRatesReportDataStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            this.setState((truckRatesReportDataStore.selectedConsignee = option));
        } else {
            this.setState((truckRatesReportDataStore.selectedConsignee = []));
        }
        this.props.truckRatesReportDataStore.statusFilterrecords();
        if(truckRatesReportDataStore.selectedConsignor.length === 0) {
            truckRatesReportDataStore.leaseOperatorName = [];
            truckRatesReportDataStore.leaseoperator = Array.from(new Set(truckRatesReportDataStore.truckRatesReports.map((item: any) => item.Consignor)));
            truckRatesReportDataStore.leaseoperator.forEach(name => {
                truckRatesReportDataStore.leaseOperatorName.push({ value: name, label: name });
        });}
    }

    handleStatusChange = (option) => {
        const { truckRatesReportDataStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            this.setState((truckRatesReportDataStore.selectedStatus = option));
        } else {
            this.setState((truckRatesReportDataStore.selectedStatus = []));
        }
        this.props.truckRatesReportDataStore.statusFilterrecords()
    }


    private getGridConfig(): GridOptions {
        const {
            truckRatesReportDataStore: { truckRatesReports, agGridService }
        } = this.props;

        const rowData = toJS(truckRatesReports)
        const columnDefs = !accountStore.userRoles.includes('ptstruckingmanager') ? truckRatesreportColDef : truckRatesMngrColDef;
        const onFilterChanged = this.onFilterChanged;
        const onCellClicked = this.onCellClicked;
        const HourlyReportGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged,
            onCellClicked,
            autoGroupColumnDef: {
                headerName: 'Quote ID',
                field: 'QuoteID',
                width: 85,
                cellRendererParams: {
                  filter: true,
                },
                rememberGroupStateWhenNewData: true,
            }
        };
        return agGridService.getGridConfig(HourlyReportGridParams);
    }

    onCellClicked = async event => {
        console.log(event.column, event)
        if (event.column.getColId() == 'ag-Grid-AutoColumn') {
            this.props.truckRatesReportDataStore.getQuoteIDDetails(event.data);
        }
        if (event.column.getColId() == 'LinkToPDF') {
            console.log(event.data.Version, !event.data.Version)
            this.props.truckRatesReportDataStore.downloadPDF(event.data.QuoteID, !event.data.Version || event.data.Version === null ? 0 : event.data.Version);
        }
    };

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.truckRatesReportDataStore.agGridService.getNodes();
        localStorageService.set(dataStore.getCurrentModuleName() + '_filter', this.props.truckRatesReportDataStore.agGridService.getAllFilters());
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        if (this.props.truckRatesReportDataStore.clearSelection == true) {
            this.setState((truckRatesReportDataStore.selectedConsignee = []))
            this.setState((truckRatesReportDataStore.selectedConsignor = []))
            this.setState((truckRatesReportDataStore.selectedStatus = []))
            this.props.truckRatesReportDataStore.clearSelection = false;
            this.props.truckRatesReportDataStore.consigneeName = this.props.truckRatesReportDataStore.backupConsigneeDropdown;
            this.props.truckRatesReportDataStore.leaseOperatorName = this.props.truckRatesReportDataStore.backupConsignorDropdown;
            this.props.truckRatesReportDataStore.statusFilterrecords();
        }
    };
}
