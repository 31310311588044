export const operationsSelectOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'TruckRates', label: 'Truck Rates' },
    { value: 'TicketApproval', label: 'Ticket Approval' },
    { value: 'PastDueCertificationReport', label: 'Past Due Certification Report' },
    { value: 'HazardAssessmentHistory', label: 'Hazard ID Management' }
];

export enum OperationsComponentName {
    Select = 'Select',
    TicketApproval = 'TicketApproval',
    PastDueCertificationReport = 'PastDueCertificationReport',
    HazardAssessmentHistory = 'HazardAssessmentHistory',
    TruckRates = 'TruckRates'
}

export const IMPORT = 'IMPORT';
export const APPROVE = 'Approve';
export const SENDTORA = 'Send To RA';
export const EXPORTPDF = 'Export PDF'

export const rowDetails = [
    { 
        IsSelected: false,
        QuoteID: 'Q-0046233',
        Consignee: '100/13-02-040-24W3/00 - Plains Midstream Canada Unity',
        ConsigneeLSD: '100/13-02-040-24W3',
        Consignor: '100/10-11-020-01W4/00 - Plains Midstream Canada Empress',
        ConsignorLSD: '100/10-11-020-01W4',
        TransportType: '',
        Product: 'Brine',
        LeaseOperatorRate: '',
        ProducerRate: 34.54,
        Margin: '45',
        Density: '23',
        LoadSize: '45',
        GravelMiles: '123',
        PavedMiles: '344',
        TotalMiles: '467',
        DriveTime: '34',
        PumpTime: '11',
        ExtraTime: '44',
        AdjustedTime: '1',
        Comments: 'qtwteuyejuweru',
        GravelSpeed: '12',
        PavelSpeed: '45',
        QuoteStatus: 'In Review',
        Status: 'In Review',
        LoadingDistrict: 'xyz',
        CreatedBy: 'XXXXX',
        CreatedDate: '2024-11-3',
        ApprovedDate: '',
        ApprovedBy: '',
        LinkToPDF: '',
        EffectiveDate: '2024-02-02',
        ExpiryDate: '2025-04-02',
        SendToRADate: '',
        LatestVersion: 3,
    },
    { 
        IsSelected: false,
        QuoteID: 'Q-0067648',
        Consignee: '100/08-20-074-16W5/01 - Plains Midstream Canada High Prairie',
        ConsigneeLSD: '100/08-20-074-16W5',
        Consignor: '100/14-30-072-04W5/00 - Cardinal Energy Ltd.',
        ConsignorLSD: '100/14-30-072-04W5',
        TransportType: '',
        Product: 'NGL',
        LeaseOperatorRate: '',
        ProducerRate: '',
        Margin: '45',
        Density: '23',
        LoadSize: '45',
        GravelMiles: '123',
        PavedMiles: '344',
        TotalMiles: '467',
        Comments: 'uiieoir',
        DriveTime: '34',
        PumpTime: '11',
        ExtraTime: '44',
        AdjustedTime: '1',
        GravelSpeed: '12',
        PavelSpeed: '45',
        QuoteStatus: 'Econ',
        Status: 'In Review',
        LoadingDistrict: 'xyz',
        CreatedBy: 'XXXXX',
        CreatedDate: '',
        ApprovedDate: '',
        ApprovedBy: 'YYY',
        LinkToPDF: '',
        CalculationDate: '',
        EffectiveDate: '2024-02-02',
        ExpiryDate: '2025-04-02',
        SendToRADate: '',
        LatestVersion: 0,
    },
    { 
        IsSelected: false,
        QuoteID: 'Q-0076543',
        Consignee: '100/11-02-080-08W5 - Plains Midstream Canada Nipisi',
        ConsigneeLSD: '100/11-02-080-08W5',
        Consignor: '100/15-25-082-18W6/00 - NorthRiver Midstream Operations GP Inc.',
        ConsignorLSD: '100/15-25-082-18W6/00',
        TransportType: '',
        Product: 'NGL',
        LeaseOperatorRate: '',
        ProducerRate: '',
        Margin: '45',
        Density: '23',
        LoadSize: '45',
        GravelMiles: '123',
        PavedMiles: '344',
        TotalMiles: '467',
        Comments: 'uiieoir',
        DriveTime: '34',
        PumpTime: '11',
        ExtraTime: '44',
        AdjustedTime: '1',
        GravelSpeed: '12',
        PavelSpeed: '45',
        QuoteStatus: 'Approved',
        Status: 'Approved',
        LoadingDistrict: 'xyz',
        CreatedBy: 'XXXX',
        CreatedDate: '',
        ApprovedDate: '',
        ApprovedBy: 'YYY',
        LinkToPDF: '',
        CalculationDate: '',
        EffectiveDate: '2024-02-02',
        ExpiryDate: '2025-04-02',
        SendToRADate: '',
        LatestVersion: 0,
    }
]
