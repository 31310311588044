import { action, computed, observable, toJS } from 'mobx';
import { UiService, uiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { HourlyReportResponse, DateRange } from '../domains/model';
import { ReportMessage } from '../domains/enum';
import { hourlyReportColDef } from '../domains/hourly-report-col-def';
import { CCUserRoles } from '../reports_constants';
import { INVALID_CCID, INVALID_USERID } from 'features/data_management/data_constant';
import { TruckRatesReporDataService } from '../services/truckRatesreport-data-services';
import { truckRatesreportColDef } from '../domains/truckRatesreport-col-def';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { dataStore } from 'features/common/stores';
import { dateUtils } from 'shared/services/date-utils';
import moment from 'moment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export class TruckRatesReportDataStore {
    @observable truckRatesReports: any[] = [];
    @observable dailyBackupReport: any = [];
    @observable selectedConsignor: any = []
    @observable selectedConsignee: any = []
    @observable selectedStatus: any = []
    clearSelection: boolean = false;
    selectButtonText: string = 'Select All';
    leaseOperatorName: any[] = [];
    consigneeName: any[] = [];
    leaseoperator: any[] = [];
    consignee: any[] = [];
    backupConsignorDropdown: any;
    backupConsigneeDropdown: any;
    userID = 0;
    userName = '';
    userInfo: any;
    constructor(
        private hourlyreportDataService: TruckRatesReporDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.userInfo = this.accountStore.getUserInfo();
        this.userName = this.accountStore.displayName;
        this.getHourlyReports();
    }

    getColDef() {
        return truckRatesreportColDef;
    }

    @Loader
    @Catch(() => errorHandler(ReportMessage.HOURLY_ERROR_MESSAGE))
    async getHourlyReports() {
        const reqbody: any = {};
            if(this.userID && this.userID !== 0){
                reqbody.CCUID = this.userID;
            }
            else{
                errorHandler(INVALID_USERID);
                return;
            }
            
        
        const truckRatesReports = await this.hourlyreportDataService.getTruckRatesReport();
        

        this.truckRatesReports = truckRatesReports;
        this.dailyBackupReport = truckRatesReports;
        if (this.truckRatesReports.length == 0) {
            errorHandler(ReportMessage.NO_RECORDS);
        }
        this.leaseOperatorName = [];
        this.consigneeName = [];
        this.leaseoperator = Array.from(new Set(this.truckRatesReports.map((item: any) => item.Consignor)));
        this.consignee = Array.from(new Set(this.truckRatesReports.map((item: any) => item.Consignee)));
        this.leaseoperator.forEach(name => {
            this.leaseOperatorName.push({ value: name, label: name });
        });
        this.backupConsignorDropdown = this.leaseOperatorName;
        this.consignee.forEach(name => {
            this.consigneeName.push({ value: name, label: name });
        });
        this.backupConsigneeDropdown = this.consigneeName;
        this.statusFilterrecords();
    }
    

    statusFilterrecords() {
        let reports: any = [];
        const selectedConsignorName = Array.from(new Set(this.selectedConsignor.map((item: any) => item.value)));
        const selectedConsigneeName = Array.from(new Set(this.selectedConsignee.map((item: any) => item.value)));
        const selectedStatus = Array.from(new Set(this.selectedStatus.map((item: any) => item.value)));

        if (selectedConsignorName.length > 0 && selectedConsigneeName.length > 0 && selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus) && selectedConsignorName.includes(element.Consignor) && selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedConsignorName.length > 0 && selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus) && selectedConsignorName.includes(element.Consignor);
            });
        }
        else if (selectedConsigneeName.length > 0 && selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus) && selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedConsignorName.length > 0 && selectedConsigneeName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedConsignorName.includes(element.Consignor) && selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedConsignorName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedConsignorName.includes(element.Consignor);
            });
        }
        else if (selectedConsigneeName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedConsigneeName.includes(element.Consignee);
            });
        }
        else if (selectedStatus.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return selectedStatus.includes(element.QuoteStatus);
            });
        }
        else {
            reports = this.dailyBackupReport
        }
        this.truckRatesReports = reports;
    }

    @Loader
    @Catch(() => errorHandler('QuoteID details cannot be shown'))
    @action
    async getQuoteIDDetails(rowDetails): Promise<void> {
        const confirmService = new ConfirmService();
        confirmService.showQuoteIDPopup(() => {
            this.downloadPDF(rowDetails.QuoteID, !rowDetails.Version || rowDetails.Version === null ? 0 : rowDetails.Version);
            
        },(rowDetails));
} 

base64ToBlob = (base64: string, mimeType: string): Blob => {
    const byteCharacters = atob(base64);
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
};
@Catch(() => errorHandler('File could not be downloaded. Please try again later.'))
    @action
    async downloadPDF (currentQuoteID, version) : Promise<void> {
        if(version >= 1){
            uiService.loaderService.showLoader();
            try {
                const reqBody = {
                    QuoteID: currentQuoteID,
                    Version: version
                };
                const response = await this.hourlyreportDataService.downloadTruckRatesPDF(reqBody);
                if (response) {           
                    const pdfBlob = this.base64ToBlob(response.Data.PDFUrl, 'application/pdf');
                    const pdfURL = URL.createObjectURL(pdfBlob);
                    // const newTab = window.open(pdfURL, '_blank');
                    const link = document.createElement('a');
                    link.href = pdfURL;
                    link.download = `${currentQuoteID}_V${version}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    alert('Failed to download the PDF.');
                }  
                uiService.loaderService.hideLoader();
                } catch (error) {
                    uiService.loaderService.hideLoader();
                alert('An error occurred while downloading the PDF.');
            }
        }
    };

    createHeaderCell(col) {
        let headerCell: any = {};

        let headerName: any = col.colDef.headerName;

        headerCell.text = headerName;
        headerCell.colId = col.getColId();
        headerCell['style'] = 'tableHeader';
        return headerCell;
    }

    createTableCell(cellValue, colId) {
        const tableCell = {
            text: cellValue !== undefined ? cellValue : '',
            style: 'tableCell'
        };
        return tableCell.text;
    }

    isColumnDisabled = () => {
        const currentUserRoles = this.accountStore.userRoles;
        return currentUserRoles.includes('ptstruckingmanager');
    };

    handleCSV = () => {
        const data = this.truckRatesReports;
        const access = this.isColumnDisabled();
        const excelrowdata = data.map((item) => ({
            'Quote ID' : item.QuoteID,
            'Quote Status' : item.QuoteStatus,
            'Consignor' : item.Consignor,
            'Consignor LSD' : item.ConsignorLSD,
            'Consignee' : item.Consignee,
            'Consignee LSD' : item.ConsigneeLSD,
            'Transport Type' : item.TransportType,
            'Product' : item.Product,
            'Lease Operator Rate' : item.LeaseOperatorRate,
            ...(access ? {} : { 'Producer Rate': item.ProducerRate }),
            ...(access ? {} : { 'Margin %' : item.Margin}),
            'Comments' : item.Comments,
            'PDF Version' : item.Version,
            'Uploaded Date': item?.ImportDate ? dateUtils.getFormattedMSTDateTime(moment.utc(item?.ImportDate)) : '',
            'Approved Date': item?.ApprovedDate ? dateUtils.getFormattedMSTDateTime(moment.utc(item?.ApprovedDate)) : '',
            'Effective Date' : item?.EffectiveDate ? dateUtils.getFormattedMSTDateTime(item?.EffectiveDate) : '',
            'Expiry Date' : item?.ExpiryDate ? dateUtils.getFormattedMSTDateTime(item?.ExpiryDate) : '',
            'Density' : item.Density,
            'Load Size' : item.LoadSize,
            'One Way Gravel Miles' : item.GravelMiles,
            'One Way Paved Miles' : item.PavedMiles,
            'Total Miles' : item.TotalMiles,
            'Drive Time' : item.DriveTime,
            'Pump Time' : item.PumpTime,
            'Extra Time' : item.ExtraTime,
            'Total Adjusted Time' : item.AdjustedTime,
            'Speed On Gravel' : item.GravelSpeed,
            'Speed On Paved' : item.PavedSpeed,
            'Status' :item ?.ExpiryDate ? moment().toISOString() > item?.ExpiryDate ? 'Expired' : 'Active' : '' ,
            'Loading District' : item.LoadingDistrict,
            'Created Date' : item?.ImportDate ? dateUtils.getFormattedMSTDateTime(moment.utc(item?.ImportDate)) : '',
            'Created By' : item.ImportByName,
            'Approved By' : item.ApprovedByName,
            'Calculation Date' : item?.CalculationDate ? dateUtils.getFormattedMSTDateTime(item?.CalculationDate) : '',
            'Uploaded By' : item.ImportByName,
            'Last Synced To RA Date' : item.SendToRADate,  
          }));
        
        // const tableData: any = this.agGridService?.getNodes();
        //     const columns: any = this.agGridService?.getColumns();
        //     let columnsToExport: any = [];
        //     let columnHeader: any = [];
        //     let rowsToExport: any = [];
        //     columns.getAllDisplayedColumns().forEach(col => {
        //         if (!['LinkToPDF'].includes(col.getColId())) {
        //             let headerCell: any = this.createHeaderCell(col);
        //             columnsToExport.push(headerCell);
        //             columnHeader.push(headerCell.text);
        //         }
        //     });
        //     tableData.forEachNodeAfterFilterAndSort(node => {
        //         const field = {};
        //         columnsToExport.forEach(({ colId, text }) => {
        //             let colsToBeFormatted: any = ['Quote ID', 'Quote Status', 'Consignor', 'Consignor LSD', 'Consignee', 'Consignee LSD', 'Transport Type','Product' , 'Lease Operator Rate' , 'Producer Rate' , 'Margin %', 'Comments', 'Version' , 'Effective Date', 'Expiry Date', 'Updated Date']
        //             let cellValue = tableData.getValue(colId, node);
        //             let tableCell = this.createTableCell(cellValue, colId);
        //             field[text] = tableCell;
        //             return field;
        //         });
        //         rowsToExport.push(field);
        //     });
        //     const excelrowdata = rowsToExport.filter(x => {
        //         return x['Quote ID'] !== '' && x['Quote Status'] !== ''
        //     })

            const ws = XLSX.utils.json_to_sheet(excelrowdata);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const exceldata: Blob = new Blob([excelBuffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(exceldata, dataStore.getCurrentModuleName());
    }
   
}
