import React from 'react';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { TruckRatesDataStore } from '../stores/truckRates-data-store';

export interface LinkToPdfProps {
    onSuccessClick: (base64String: string) => void;
    onClose: () => void;
    currentQuoteID: String;
    version: any;
    handleDownloadClick(currentQuoteID: String);
}

interface LinkToPDDFProps {
    truckRatesStore: TruckRatesDataStore;
}
export class LinkToPdfPopup extends React.Component<LinkToPdfProps> {
    blobURL: any = [];
    constructor(props) {
        super(props);
    }
    state = {
        base64String: '',
    };

    fileInputRef = React.createRef<HTMLInputElement>();

    arrayBufferToBase64 = (buffer: ArrayBuffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            alert('No file selected.');
            return;
        }
        this.setState({ file });
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryData = e?.target?.result;
            if (binaryData instanceof ArrayBuffer) {
                const base64String = this.arrayBufferToBase64(binaryData);
                this.setState({ base64String });
                // this.props.onSuccessClick(base64String); 
            } else {
                console.error('Failed to read file data.');
            }
        };
        reader.readAsArrayBuffer(file);
    };

    handleUploadClick = () => {
        this.props.onSuccessClick(this.state.base64String); 
        this.props.onClose();

    };

    formatBase64String = (base64: string, chunkSize = 100): string => {
        const chunks: string[] = [];
        for (let i = 0; i < base64.length; i += chunkSize) {
            chunks.push(base64.slice(i, i + chunkSize));
        }
        return chunks.map(chunk => `"${chunk}"`).join(" +\n");
    };
    
    handleDownloadClick = async () => {
        const { currentQuoteID} = this.props;
        this.props.handleDownloadClick(currentQuoteID);
        this.props.onClose();
    };

    render() {
        const { onClose, version } = this.props;
        return (
            <div className="linktopdfpopup">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h6>Link To Pdf</h6>
                </div>
                {/* QuoteID Section */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'left', gap: '65px' }}>
                <div style={{ fontWeight: 'bold' }}>QuoteID</div>
              
                <div>{this.props.currentQuoteID}</div>
                </div>
                </div>
    
                {/* Year and Month Section */}
                {/* <div style={{ display: 'flex', flexDirection: 'row',alignItems: 'center', marginBottom: '10px', gap: '140px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '90px' }}> */}
                {/* <div style={{ display: 'flex', flexDirection: 'row',alignItems: 'center', marginBottom: '10px', gap: '80px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '90px' }}>
                        <div style={{ fontWeight: 'bold' }}>Year</div>
                        <div style={{ textAlign: 'left' }}>2024</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'left', gap: '30px' }}>
                        <div style={{ fontWeight: 'bold' }}>Month</div>
                        <div style={{ textAlign: 'left' }}>10</div>
                    </div>
                </div> */}

                <div style={{ display: 'flex', flexDirection: 'row',alignItems: 'center', marginBottom: '10px', gap: '70px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '70px' }}>
                        <div style={{ fontWeight: 'bold' }}>Version</div>
                        <div style={{ textAlign: 'left' }}>{version == 0 ? '' : version}</div>
                    </div>
                </div>
    
                {/* Truck Routes Section */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px',gap: '20px'  }}>
                    <div style={{ fontWeight: 'bold' }}>Truck Routes</div>
                    <input
                        type="file"
                        accept="application/pdf"
                        onChange={this.handleFileChange}
                        ref={this.fileInputRef}
                        // style={{ display: 'none' }}
                    />
                    
                </div>
    
                {/* OK Button Section */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <CustomButton type={CustomButtonType.Ok} onClick={this.handleOkClick} disabled={this.state.base64String === ''}>
                        OK
                    </CustomButton> */}
                    <CustomButton type={CustomButtonType.Submit} onClick={this.handleUploadClick} disabled={this.state.base64String === ''}>
                        Upload
                    </CustomButton>
                    <CustomButton 
                    type={CustomButtonType.Submit} 
                    disabled={this.props.version === 0}
                    onClick={this.handleDownloadClick }
                    >
                        Download
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={onClose} >
                        Cancel
                    </CustomButton>
                </div>
            </div>
        );
    }   
}