import { Option } from '../types/shared';
export const Years: Option[] = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: '2018',
        value: '2018'
    },
    {
        label: '2019',
        value: '2019'
    }
];

export const Months: Option[] = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'January',
        value: 'Jan'
    },
    {
        label: 'February',
        value: 'Feb'
    },
    {
        label: 'March',
        value: 'Mar'
    },
    {
        label: 'April',
        value: 'Apr'
    },
    {
        label: 'May',
        value: 'May'
    },
    {
        label: 'June',
        value: 'Jun'
    },
    {
        label: 'July',
        value: 'Jul'
    },
    {
        label: 'August',
        value: 'Aug'
    },
    {
        label: 'September',
        value: 'Sep'
    },
    {
        label: 'October',
        value: 'Oct'
    },
    {
        label: 'November',
        value: 'Nov'
    },
    {
        label: 'December',
        value: 'Dec'
    }
];

export const ForeCastingData: Option[] = [
    {
        label: 'All',
        value: 'All'
    },
    {
        label: 'EBITDA',
        value: 'EBITDA'
    },
    {
        label: 'Revenue',
        value: 'Revenue'
    }
];

export const DocumentTypes: Option[] = [
    { value: 'All', label: 'All' },
    { value: 'Invoice', label: 'Invoice' },
    { value: 'Contract', label: 'Contract' }
];

export const PathDataTypeMap: any = [
    { path: '/locationMgt', dataTypeValue: 'LocationManagement' },
    { path: '/locationMap', dataTypeValue: 'LocationMaping' },
    { path: '/driverMgt', dataTypeValue: 'DriverManagement' },
    { path: '/trailerMgt', dataTypeValue: 'TrailerManagement' },
    { path: '/tractorMgt', dataTypeValue: 'TractorManagement' },
    { path: '/companyMgt', dataTypeValue: 'CompanyManagement' },
    { path: '/shopMgt', dataTypeValue: 'ShopManagement' },
    { path: '/tabletMgt', dataTypeValue: 'TabletManagement' },
    { path: '/printerMgt', dataTypeValue: 'PrinterManagement' },
    { path: '/regionMapping', dataTypeValue: 'RegionMapping' },
    { path: '/operations/ticketApproval', dataTypeValue: 'TicketApproval' },
    { path: '/maintenance/inspectionTicketHistory', dataTypeValue: 'InspectionTicketHistory' },
    { path: '/maintenance/trailerBookingMaint', dataTypeValue: 'TrailerBookingMaintenance' },
    { path: '/userApproval/manageUsers', dataTypeValue: 'UserApproval' },
    { path: '/reports/dailyReport', dataTypeValue: 'DailyReport' },
    { path: '/reports/hourlyReport', dataTypeValue: 'HourlyReport' },
    { path: '/reports/leaseOperator', dataTypeValue: 'LeaseOperatorReport' },
    { path: '/reports/customerReport', dataTypeValue: 'CustomerReport' },
    { path: '/reports/rejectedLoadsReport', dataTypeValue: 'RejectedLoads' },
    { path: '/dispatch/communication', dataTypeValue: 'Communication' },
    { path: '/dispatch/nomination', dataTypeValue: 'Nomination' },
    { path: '/dispatch/capacityPlan', dataTypeValue: 'CapacityPlan' },
    { path: '/dispatch/dispatchBoard', dataTypeValue: 'DispatchBoard' },
    { path: '/dispatch/dispatchPlan', dataTypeValue: 'DispatchPlan' },
    { path: '/dispatch/apportionment', dataTypeValue: 'Apportionment' },
    { path: '/reports/scheduledReports', dataTypeValue: 'ScheduledReports' },
    { path: '/operations/pastDueCertificationReport', dataTypeValue: 'PastDueCertificationReport' },
    { path: '/dispatch/nominationWorkbench', dataTypeValue: 'NominationWokbench' },
    { path: '/operations/hazardAssessmentHistory', dataTypeValue: 'HazardAssessmentHistory' },
    { path: '/reports/upcomingCertificationReport', dataTypeValue: 'UpcomingCertificationReport' },
    { path: '/reports/searchTickets', dataTypeValue: 'SearchTickets' },
    { path: '/reports/masterTicketReport', dataTypeValue: 'MasterTicketReport' },
    { path: '/dispatch/dispatchScreen', dataTypeValue: 'DispatchScreen' },
    { path: '/keyFobs', dataTypeValue: 'KeyFobManagement'},
    { path: '/operations/truckRates', dataTypeValue: 'TruckRates'},
    { path: '/reports/truckRatesReport', dataTypeValue: 'TruckRatesReport'}
];
