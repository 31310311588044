import { ResponseDataType } from 'shared/types/shared';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { HourlyReportResponse, DateRange } from '../domains/model';

export class TruckRatesReporDataService {
    constructor(private restApi: RestApiService) {}

    async getTruckRatesReport(): Promise<[]> {
        const { data } = await this.restApi.postWithToken<[]>(Config.getTruckRatesReport);
        return data['Data'];
    }
    async downloadTruckRatesPDF(reqBody: {} | undefined) {
        const { data } = await this.restApi.post<any>(Config.DownloadTruckRatesPDF, reqBody, {});
        return data;
    } 
}

export const truckratesreprotDataService = new TruckRatesReporDataService(restApiService);
