export enum truckRatesHeader {
    QUOTEID = 'Quote ID',
    CONSIGNEE = 'Consignee',
    CONSIGNEELSD = 'Consignee LSD',
    CONSIGNOR = 'Consignor',
    CONSIGNORLSD = 'Consignor LSD',
    TRANSPORTTYPE = 'Transport Type',
    PRODUCT = 'Product',
    LOADINGDISTRICT = 'Loading District',
    DENSITY = 'Density',
    LOADSIZE = 'Load Size (m3)',
    GRAVELSPEED = 'Speed on Gravel (mph)',
    PAVEDSPEED = 'Speed on Pavel (mph)',
    GRAVELMILES = 'One-Way Gravel Miles',
    PAVEDMILES = 'One-Way Paved Miles',
    TOTALMILES = 'Total Miles',
    DRIVETIME = 'Drive Time',
    PUMPTIME = 'Pump Time',
    EXTRATIME = 'Extra Time',
    ADJUSTEDTIME = 'Adjusted Time',
    QUOTESTATUS = 'Quote Status',
    LEASEOPERATORRATE = 'Lease Operator Rate',
    PRODUCERRATE = 'Producer Rate',
    MARGIN = 'Margin %',
    CALCULATIONDATE = 'Calculation Date',
    EFFECTIVEDATE = 'Effective Date',
    EXPIRYDATE = 'Expiry Date',
    LINKTOPDF = 'Link To PDF',
    COMMENTS = 'Comments',
    ORIGINERROR = 'Origin Error',
    DESTINATIONERROR = 'Destination Error',
    SENDTORABY = 'Send To RA By',
    SENDTORADATE = 'Send To RA Date',
    SENDTORASTATUS = 'Send To RA Status'
}

export enum truckRatesFieldName {
    QUOTEID = 'QuoteID',
    CONSIGNEE = 'Consignee',
    CONSIGNEELSD = 'ConsigneeLSD',
    CONSIGNOR = 'Consignor',
    CONSIGNORLSD = 'ConsignorLSD',
    TRANSPORTTYPE = 'TransportType',
    PRODUCT = 'Product',
    LOADINGDISTRICT = 'LoadingDistrict',
    DENSITY = 'Density',
    LOADSIZE = 'LoadSize',
    GRAVELSPEED = 'GravelSpeed',
    PAVEDSPEED = 'PavelSpeed',
    GRAVELMILES = 'GravelMiles',
    PAVEDMILES = 'PavedMiles',
    TOTALMILES = 'TotalMiles',
    DRIVETIME = 'DriveTime',
    PUMPTIME = 'PumpTime',
    EXTRATIME = 'ExtraTime',
    ADJUSTEDTIME = 'AdjustedTime',
    QUOTESTATUS = 'QuoteStatus',
    LEASEOPERATORRATE = 'LeaseOperatorRate',
    PRODUCERRATE = 'ProducerRate',
    MARGIN = 'Margin',
    CALCULATIONDATE = 'CalculationDate',
    EFFECTIVEDATE = 'EffectiveDate',
    EXPIRYDATE = 'ExpiryDate',
    LINKTOPDF = 'LinkToPDF',
    COMMENTS = 'Comments',
    ORIGINERROR = 'IsOriginError',
    DESTINATIONERROR = 'IsDestinationError',
    SENDTORABY = 'SendToRABy',
    SENDTORADATE = 'SendToRADate',
    SENDTORASTATUS = 'SendToRAStatus'
    }