import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { MyDocument } from './export-to-pdf-preview-component';
import DatePicker from 'react-datetime';
import { truckRatesDataService } from '../services/truckRates-data-service';

export interface ExportToPdfProps {
    onSuccessClick: () => void;
    onClose: () => void;
    quoteData: any;
}

export class ExportToPdfPopup extends React.Component<ExportToPdfProps> {
    // constructor{
    //     public agGridService: AgGridService,
    // }

    formatDate = (dateString: string): string => {
        if (!dateString) return 'N/A';
        return dateString.replace('T', ' ').split('.')[0];
    };

    state = {
        CustomerName: this.props.quoteData[0].Consignor,
        EffectiveDate: this.formatDate(this.props.quoteData[0].EffectiveDate),
    };

    handleCustomerNameChange = (e) => {
        this.setState({ CustomerName: e.target.value });
    };

    handleEffectiveDateChange = (date) => {
        this.setState({ EffectiveDate: date.format('YYYY-MM-DD HH:mm:ss') ,
        });
        
    };

    handleSubmit = async () => {
        const result  = await truckRatesDataService.getTruckRatesPdfValues();
        const tRPdfAddress = result.find(x => x.Code == 'TruckRatesPDFAddre').Value;
        const tRPdfNotes = result.find(x => x.Code == 'TruckRatesPDFNotes').Value;
        const { CustomerName, EffectiveDate } = this.state;
        const { onClose, quoteData, onSuccessClick } = this.props;
        const pdfBlob = await require('@react-pdf/renderer').pdf(
            <MyDocument quoteData={quoteData} values={{ customerName: CustomerName, effectiveDate: EffectiveDate, address: tRPdfAddress, notes:tRPdfNotes }} />
        ).toBlob();
        const pdfURL = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfURL;
        link.download = 'TruckRates.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(pdfURL);
        onSuccessClick();
        onClose();
    };

    handleCancel = () => {
        const { onClose, onSuccessClick } = this.props;
        onSuccessClick();
        onClose();
    }

    render() {
        const { CustomerName, EffectiveDate } = this.state;
        const labelStyle = {
            fontSize: '13px',
            fontWeight: 700,
        };
        const inputStyle = {
            fontSize: '13px',
        };

        return (
            <div className="exporttopdfpopup">
                <div className="popup-body">
                    <Row className="form-group align-items-center">
                        <Col xs={4}>
                            <label htmlFor="customerName" style={labelStyle}>Customer Name:</label>
                        </Col>
                        <Col xs={8}>
                            <input
                                id="customerName"
                                type="text"
                                className="form-control"
                                placeholder="Enter Customer Name"
                                value={CustomerName}
                                onChange={this.handleCustomerNameChange}
                                style={inputStyle}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group align-items-center">
                            <Col xs={4}>
                                <label htmlFor="effectiveDate" style={labelStyle}>Effective Date:</label>
                            </Col>
                            <Col xs={8}>
                                <DatePicker
                                    inputProps={{
                                        id: 'effectiveDate',
                                        className: 'form-control',
                                        placeholder: 'Select Effective Date',
                                        style: inputStyle,
                                    }}
                                    value={EffectiveDate}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm:ss"
                                    onChange={this.handleEffectiveDateChange}
                                    closeOnSelect={false}

                                />
                            </Col>
                        </Row>
                </div>
                <div className="popup-footer">
                    <Row>
                        <Col xs={6} className="text-right">
                            <CustomButton
                                type={CustomButtonType.Ok}
                                onClick={this.handleSubmit}
                                disabled={!CustomerName || !EffectiveDate}
                            >
                                OK
                            </CustomButton>
                        </Col>
                        <Col xs={6} className="text-left">
                            <CustomButton type={CustomButtonType.Cancel} onClick={this.handleCancel}>
                                Cancel
                            </CustomButton>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}